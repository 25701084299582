import { useClassNames } from "@metaforcelabs/metaforce-core";
import React, { useContext } from "react";
import { SidebarButton } from "./sideBarButton";
import { SidebarContext, WorkflowContext } from "../../../contexts";
import { workflowStates } from "../../../utils/constants";
import { CirclePlay, CircleStop, HardDriveUpload } from "lucide-react";

export default function Sidebar() {
    const { classNames } = useClassNames();
    const sidebarContext = useContext(SidebarContext);
    const {workFlow} = useContext(WorkflowContext);
    return (
        <div className="w-full h-full">
            <div className="h-full flex flex-col bg-gray-50 rounded-lg border border-gray-200">
                <div className='py-2 px-3 w-full border-b '>
                    <div className="hidden sm:block pt-2 pb-2">
                        <nav className="flex space-x-4" aria-label="Tabs">
                            <span
                                className={classNames(
                                    'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                    'px-3 py-1 font-medium text-sm rounded-md'
                                )}
                            // aria-current={tab.current ? 'page' : undefined}
                            >
                                Actions
                            </span>
                        </nav>
                    </div>
                </div>
                <div className="my-6 relative flex-1 overflow-y-auto">
                    <div className={`mb-8 border-gray-200 pb-7`}>
                        <div className='h-full px-4 sm:px-6 '>
                            <h3 className="font-medium text-gray-900">General</h3>
                            <div className={`pt-3 grid grid-cols-2 gap-2`}>
                                <SidebarButton
                                    disabled={sidebarContext.publishDisabled}
                                    text='Publish'
                                    onClick={() => sidebarContext?.onPublish()}
                                    icon={()=><HardDriveUpload className="h-4 w-4"/>}
                                    readOnly={false}
                                />
                                {
                                    workFlow.workflowDefinitionState === workflowStates.active ? (
                                        < SidebarButton
                                            disabled={sidebarContext.publishDisabled}
                                            text='Disable'
                                            onClick={() => sidebarContext?.onDisable()}
                                            icon={()=><CircleStop className="h-4 w-4"/>}
                                            readOnly={false}
                                        />

                                    ) : (
                                        <SidebarButton
                                            disabled={sidebarContext.publishDisabled}
                                            text='Activate'
                                            onClick={() => sidebarContext?.onActivate()}
                                            icon={()=><CirclePlay className="h-4 w-4"/>}
                                            readOnly={false}
                                        />
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}